@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  background-color: #171717;
  color: white;
  margin: 0;
  font-family: "Montserrat", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code,
.code {
  font-family: "VT323", monospace;
}

.Typewriter {
  display: inline;
}

.landingLogo {
  animation-name: landingLogo;
  animation-delay: 1000ms;
  animation-duration: 2500ms;
  animation-fill-mode: forwards;
  animation-timing-function: linear;
}

@keyframes landingLogo {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.landingTitleBorder {
  animation-name: bottomBorderDraw;
  animation-duration: 1500ms;
  animation-fill-mode: forwards;
  animation-delay: 5s;
  animation-timing-function: ease-in-out;
}

@keyframes bottomBorderDraw {
  from {
    width: 0%;
  }
  to {
    width: 100%;
  }
}

.pageTitleBorder {
  animation-name: bottomBorderDraw;
  animation-duration: 2s;
  animation-fill-mode: forwards;
  animation-timing-function: ease-in-out;
}

.tagline {
  animation-name: taglineAnimation;
  animation-duration: 2s;
  animation-fill-mode: forwards;
  animation-delay: 7s;
  animation-timing-function: linear;
}

@keyframes taglineAnimation {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.pageTagline {
  animation-name: taglineAnimation;
  animation-duration: 2s;
  animation-fill-mode: forwards;
  animation-delay: 3s;
  animation-timing-function: linear;
}
